import { existsWithLength } from '@aldridge/aldg-helpers';
import { list, ref } from 'firebase/storage';
import dayjs from 'dayjs';
import { storage } from '../firebase';

const errorCheck = async (record, approved = false) => {
  // eslint-disable-next-line no-unused-vars
  const rec = { ...record };
  const errors = [];
  const filePath = `${rec.id}/DesignPhotos`;
  const listRef = await list(ref(storage, filePath));
  if (!existsWithLength(listRef.items)) {
    errors.push('Design Photos');
  }
  if (rec.data.IsGLTicket?.toString() === 'true') {
    if (rec.data.DepartmentNumber.length === 0) errors.push('Department');
    if (rec.data.ScopeOfWork.length === 0) errors.push('Scope of Work');
    if (rec.data.BriefDescription === '') errors.push('Brief Description');
  } else {
    if (rec.data.JobNumber.length === 0) errors.push('Job Number');
    if (rec.data.Picc.length === 0) errors.push('Picc');
    if (rec.data.DateNeededBy === '') errors.push('PreFab Completed By');
    if (dayjs().isAfter(dayjs(rec.data.DateNeededBy)))
      errors.push('PreFab Completed By Cannot be in the Past');
    if (rec.data.ScopeOfWork === '') errors.push('Scope of Work');
    if (rec.data.BriefDescription === '') errors.push('Brief Description');
    if (rec.data.TotalQuantity === '') errors.push('Total Quantity');
    if (rec.data.HoursBudgetedForPreFab === '')
      errors.push('Hours Budgeted For PreFab');
    if (rec.data.MadeInAmericaRequirement === '')
      errors.push('Made in America Requirement');
    if (rec.data.MaterialOrdered === '') errors.push('Material Ordered');
    if (rec.data.TypeOfWork === '') errors.push('Type of Work');
    if (rec.data.ApprovedByPreFabProjectTeam === 'No')
      errors.push(
        'The Design Must be Approved by Project Team and PreFab Team'
      );
    // if (rec.data.BillOfMaterialComplete === '')
    //   errors.push('Bill of Material Must be Complete and Attached');
    if (rec.data.MaterialOrdered === '')
      errors.push('Material Has Been Ordered');
    if ((rec.data.JPACLineItem || '') === '')
      errors.push('Existing JPAC Line Item');
    if (
      (rec.data.JPACLineItem || '') === 'No' &&
      (rec.data.NewJPACLineNeeded || '') === ''
    )
      errors.push('New JPAC Line Item Needed');

    if (approved) {
      if (!existsWithLength(rec.data.PreFabEstimatedHours))
        errors.push('PreFab Estimated Hours');
    }
  }

  return errors;
};

const SendStatusEmail = (
  sendEmail,
  rec,
  admins = [
    'jbuckley@aldridgegroup.com',
    'jlother@aldridgegroup.com',
    'tdoe@aldridgegroup.com'
  ]
) => {
  const r = rec;

  const html = `
    <table style="border-spacing:0;border-collapse:collapse;padding:0;vertical-align:top;text-align:left;border:1px solid #ddd;width:100%">
    <tr>
      <td colspan="2" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">UPDATE</td>
      <td colspan="2" style="font-weight:bold;">Status Updated to [${
        r.data.Status || ''
      }]</td>
    </tr>
      <tr>
        <td colspan="4" valign="top" style="font-size:16pt;font-weight:bold;text-align:left;color:#ffffff;background-color:#2c3f50;padding:0.5rem">
          <div style="margin-left:0.000px">${
            ['Approved', '4. In Progress', '5. On Hold', '6. Complete'].indexOf(
              r.data.Status
            ) > -1
              ? 'Order'
              : 'Request'
          } Information</div>
        </td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Order Number</td>
        <td>${r.data.OrderNumber || ''}</td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Brief Description of Scope</td>
        <td colspan="3">${r.data.BriefDescription || ''}</td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Status</td>
        <td>${r.data.Status}</td>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Job Number</td>
        <td>${r.data.JobNumberDisplay}</td>
      </tr>
      ${
        r.data.Status === '1. Requested'
          ? `<tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Date Needed By</td>
            <td>${dayjs(r.data.DateNeededBy).format('MM/DD/YYYY') || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Type of Work</td>
            <td>${r.data.TypeOfWork || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Brief Description of Scope of Work</td>
            <td colspan="3">${r.data.BriefDescription || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Detailed Description of Scope of Work</td>
          </tr>
          <tr>
            <td colspan="4" style="padding:0.5rem;">${
              r.data.ScopeOfWork || ''
            }</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Made In America Requirement</td>
            <td>${r.data.MadeInAmericaRequirement || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Is Bill of Material Complete and Attached?</td>
            <td>${r.data.BillOfMaterialComplete || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Material Has Been Ordered</td>
            <td>${r.data.MaterialOrdered || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Has the Design & BOM Been Reviewed and Approved by PreFab & Project Team?</td>
            <td>${r.data.ApprovedByPreFabProjectTeam || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">PO Number</td>
            <td>${r.data.PoNumber || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Estimated Ship-Date Incoming</td>
            <td>${r.data.EstimatedShipDate || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Yard Location Link</td>
            <td>${r.data.YardLinkLocation || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Build Complete Location</td>
            <td>${r.data.BuildComplete || ''}</td>
          </tr>`
          : ''
      }
      ${
        Array.isArray(r.data.PublicNotes)
          ? `<tr>
        <td colSpan="4" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Notes</td>
      </tr>
      <tr>
        <td colspan="2" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Comment</td>
        <td style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Modified Date</td>
        <td style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Modified By</td>
      </tr>
      ${r.data.PublicNotes.map(
        (note, idx) => `<tr style="background-color:${
          idx % 2 ? '#efefef' : 'white'
        };">
        <td style="text-align:left;padding:0.5rem" colspan="2">${note.body}</td>
        <td style="text-align:left;padding:0.5rem">${
          dayjs(r.data.DateNeededBy).format('MM/DD/YYYY') || ''
        }</td>
        <td style="text-align:left;padding:0.5rem">${note.mby}</td>
        </tr>`
      ).join('')}`
          : ''
      }
        ${
          r.data.Status === '6. Complete'
            ? '<tr><td colspan="4">Your Order has been completed. Please Contact materialrequests@aldridge-electric.com about getting the Material Shipped Out. Create a ship ticket using this <a href="https://docs.google.com/spreadsheets/d/1L5Gya37_SvlwEk-xIMJyCvx7h-MzPJHbKP0Pq6A_Z7Y/edit#gid=209244838">Google Doc</a>.</td></tr>'
            : ''
        }
    </table>
    <a href='${window.location.href}'>
      <div style='font-size:12pt;font-weight:bold;color:#fff;background-color:#4285f4;text-align:center;border-radius: 0.15rem;width: fit-content;margin: 0.5rem 0;padding: 0.5rem 0.75rem;'>Reply in App</div>
    </a>
`;
  const subject =
    [
      '4. In Progress',
      '6. Complete',
      'Approved',
      '5. On Hold',
      '2. Awaiting Material',
      '3. Awaiting Scheduling'
    ].indexOf(r.data.Status) > -1
      ? `Order Number ${r.data.OrderNumber}`
      : `PreFab Request for Job ${r.data.JobNumberDisplay}`;
  sendEmail({
    to: [
      ...r.SharedWith,
      ...(r.data.PreFabLead || []),
      ...(r.data.PreFabTeam || []),
      ...admins
    ].filter((rr) => existsWithLength(rr)),
    subject,
    html
  });
};
const SendJPACEmail = (sendEmail, rec) => {
  const r = rec;
  const html = `
    <table style="border-spacing:0;border-collapse:collapse;padding:0;vertical-align:top;text-align:left;border:1px solid #ddd;width:100%">
    <tr>
      <td colspan="2" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">UPDATE</td>
      <td colspan="2" style="font-weight:bold;">Status Updated to [${
        r.data.Status || ''
      }]</td>
    </tr>
      <tr>
        <td colspan="4" valign="top" style="font-size:16pt;font-weight:bold;text-align:left;color:#ffffff;background-color:#2c3f50;padding:0.5rem">
          <div style="margin-left:0.000px">${
            ['Approved', '4. In Progress', '5. On Hold', '6. Complete'].indexOf(
              r.data.Status
            ) > -1
              ? 'Order'
              : 'Request'
          } Information</div>
        </td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Order Number</td>
        <td>${r.data.OrderNumber || ''}</td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Brief Description of Scope</td>
        <td colspan="3">${r.data.BriefDescription || ''}</td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Status</td>
        <td>${r.data.Status}</td>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Job Number</td>
        <td>${r.data.JobNumberDisplay}</td>
      </tr>
      <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Date Needed By</td>
            <td>${dayjs(r.data.DateNeededBy).format('MM/DD/YYYY') || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Type of Work</td>
            <td>${r.data.TypeOfWork || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Brief Description of Scope of Work</td>
            <td colspan="3">${r.data.BriefDescription || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Detailed Description of Scope of Work</td>
          </tr>
          <tr>
            <td colspan="4" style="padding:0.5rem;">${
              r.data.ScopeOfWork || ''
            }</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Made In America Requirement</td>
            <td>${r.data.MadeInAmericaRequirement || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Is Bill of Material Complete and Attached?</td>
            <td>${r.data.BillOfMaterialComplete || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Material Has Been Ordered</td>
            <td>${r.data.MaterialOrdered || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Has the Design & BOM Been Reviewed and Approved by PreFab & Project Team?</td>
            <td>${r.data.ApprovedByPreFabProjectTeam || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">PO Number</td>
            <td>${r.data.PoNumber || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Estimated Ship-Date Incoming</td>
            <td>${r.data.EstimatedShipDate || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Yard Location Link</td>
            <td>${r.data.YardLinkLocation || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Build Complete Location</td>
            <td>${r.data.BuildComplete || ''}</td>
          </tr>
      ${
        Array.isArray(r.data.PublicNotes)
          ? `<tr>
        <td colSpan="4" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Notes</td>
      </tr>
      <tr>
        <td colspan="2" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Comment</td>
        <td style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Modified Date</td>
        <td style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Modified By</td>
      </tr>
      ${r.data.PublicNotes.map(
        (note, idx) => `<tr style="background-color:${
          idx % 2 ? '#efefef' : 'white'
        };">
        <td style="text-align:left;padding:0.5rem" colspan="2">${note.body}</td>
        <td style="text-align:left;padding:0.5rem">${
          dayjs(r.data.DateNeededBy).format('MM/DD/YYYY') || ''
        }</td>
        <td style="text-align:left;padding:0.5rem">${note.mby}</td>
        </tr>`
      ).join('')}`
          : ''
      }
        ${
          r.data.Status === '6. Complete'
            ? '<tr><td colspan="4">Your Order has been completed. Please Contact materialrequests@aldridge-electric.com about getting the Material Shipped Out. Create a ship ticket using this <a href="https://docs.google.com/spreadsheets/d/1L5Gya37_SvlwEk-xIMJyCvx7h-MzPJHbKP0Pq6A_Z7Y/edit#gid=209244838">Google Doc</a>.</td></tr>'
            : ''
        }
    </table>
    <a href='${window.location.href}'>
      <div style='font-size:12pt;font-weight:bold;color:#fff;background-color:#4285f4;text-align:center;border-radius: 0.15rem;width: fit-content;margin: 0.5rem 0;padding: 0.5rem 0.75rem;'>Reply in App</div>
    </a>
`;
  const subject = `Potential JPAC Line Item Request for Job "${r.data.JobNumberDisplay}"`;
  sendEmail({
    to: ['agile@aldridge-electric.com'],
    cc: [rec?.data.ModifiedBy],
    subject,
    html
  });
};
const SendOrderUpdateEmail = (
  sendEmail,
  rec,
  admins = [
    'jbuckley@aldridgegroup.com',
    'jlother@aldridgegroup.com'
    // 'tdoe@aldridgegroup.com'
  ]
) => {
  const r = rec;

  const html = `
    <table style="border-spacing:0;border-collapse:collapse;padding:0;vertical-align:top;text-align:left;border:1px solid #ddd;width:100%">
    <tr>
      <td colspan="2" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">UPDATE</td>
      <td colspan="2" style="font-weight:bold;">Information Update</td>
    </tr>
      <tr>
        <td colspan="4" valign="top" style="font-size:16pt;font-weight:bold;text-align:left;color:#ffffff;background-color:#2c3f50;padding:0.5rem">
          <div style="margin-left:0.000px">${
            ['Approved', '4. In Progress', '5. On Hold', '6. Complete'].indexOf(
              r.data.Status
            ) > -1
              ? 'Order'
              : 'Request'
          } Information</div>
        </td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Order Number</td>
        <td>${r.data.OrderNumber || ''}</td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Brief Description of Scope</td>
        <td colspan="3">${r.data.BriefDescription || ''}</td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Status</td>
        <td>${r.data.Status}</td>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Job Number</td>
        <td>${r.data.JobNumberDisplay}</td>
      </tr>
      ${
        r.data.Status === '1. Requested'
          ? `<tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Date Needed By</td>
            <td>${dayjs(r.data.DateNeededBy).format('MM/DD/YYYY') || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Type of Work</td>
            <td>${r.data.TypeOfWork || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Brief Description of Scope of Work</td>
            <td colspan="3">${r.data.BriefDescription || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Detailed Description of Scope of Work</td>
          </tr>
          <tr>
            <td colspan="4">${r.data.ScopeOfWork || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Made In America Requirement</td>
            <td>${r.data.MadeInAmericaRequirement || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Is Bill of Material Complete and Attached?</td>
            <td>${r.data.BillOfMaterialComplete || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Material Has Been Ordered</td>
            <td>${r.data.MaterialOrdered || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Has the Design & BOM Been Reviewed and Approved by PreFab & Project Team?</td>
            <td>${r.data.ApprovedByPreFabProjectTeam || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">PO Number</td>
            <td>${r.data.PoNumber || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Estimated Ship-Date Incoming</td>
            <td>${r.data.EstimatedShipDate || ''}</td>
          </tr>
          <tr>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Yard Location Link</td>
            <td>${r.data.YardLinkLocation || ''}</td>
            <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Build Complete Location</td>
            <td>${r.data.BuildComplete || ''}</td>
          </tr>`
          : ''
      }
      ${
        Array.isArray(r.data.PublicNotes)
          ? `<tr>
        <td colSpan="4" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Notes</td>
      </tr>
      <tr>
        <td colspan="2" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Comment</td>
        <td style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Modified Date</td>
        <td style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Modified By</td>
      </tr>
      ${r.data.PublicNotes.map(
        (note, idx) => `<tr style="background-color:${
          idx % 2 ? '#efefef' : 'white'
        };">
        <td style="text-align:left;padding:0.5rem" colspan="2">${note.body}</td>
        <td style="text-align:left;padding:0.5rem">${
          new Date(r.data.DateNeededBy).toJSON() || ''
        }</td>
        <td style="text-align:left;padding:0.5rem">${note.mby}</td>
        </tr>`
      ).join('')}`
          : ''
      }
        ${
          r.data.Status === '6. Complete'
            ? '<tr><td colspan="4">Your Order has been completed. Please Contact materialrequests@aldridge-electric.com about getting the Material Shipped Out. Create a ship ticket using this <a href="https://docs.google.com/spreadsheets/d/1L5Gya37_SvlwEk-xIMJyCvx7h-MzPJHbKP0Pq6A_Z7Y/edit#gid=209244838">Google Doc</a>.</td></tr>'
            : ''
        }
    </table>
    <a href='${window.location.href}'>
      <div style='font-size:12pt;font-weight:bold;color:#fff;background-color:#4285f4;text-align:center;border-radius: 0.15rem;width: fit-content;margin: 0.5rem 0;padding: 0.5rem 0.75rem;'>Reply in App</div>
    </a>
`;
  const subject =
    [
      '4. In Progress',
      '6. Complete',
      'Approved',
      '5. On Hold',
      '2. Awaiting Material',
      '3. Awaiting Scheduling'
    ].indexOf(r.data.Status) > -1
      ? `Order Number ${r.data.OrderNumber}`
      : `PreFab Request for Job ${r.data.JobNumberDisplay}`;
  sendEmail({
    to: [
      ...r.SharedWith,
      ...(r.data.PreFabLead || []),
      ...(r.data.PreFabTeam || []),
      ...admins
    ].filter((rr) => existsWithLength(rr)),
    subject,
    html
  });
};

export { errorCheck, SendStatusEmail, SendOrderUpdateEmail, SendJPACEmail };
